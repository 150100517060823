import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic11 from '../assets/imgs/landing/p1.jpg'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>Unidans Project</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1> Objectives </h1>
                    </header>
                    <span className="image main"><img src={pic11} alt="" /></span>
                    
                    <h3> General Objectives </h3>
                        <ul>
                            <li> Understanding the diversity of human limits in motion and in relation to one&#39;s own
body </li> 
                            <li>  Developing programs and services for all categories of people with or without
disabilities </li>
                            <li> Create a strong link between sport and sectors engaged with the issue of social
inclusion, and increase interest in sport among physically inactive people </li>
                            <li>Maximized contribution of sport to achieve goals of the Europe 2020 Strategy for
smart, sustainable and inclusive growth.</li> 
                            
                        
                        </ul>

                        <h3> Specific objectives
 </h3>
        <ul>


            <li>Understanding and integrating young people with and without disabilities in
educational dance programs </li>
            <li> Developing opportunities for dance addressed to people with disabilities </li>
            <li> Facilitate the exchange of knowledge and best practices in the domain of
adaptive/unified dance/sport among the interested parties </li>
            <li> Facilitate intercultural dialogue by including persons from different cultural
backgrounds in society through sport in the 3 partners countries </li>
        </ul>

                </div>
            </section>
        </div>

    </Layout>
)

export default Generic